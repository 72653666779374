







import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import currency from 'currency.js';
import { mean } from 'lodash';
import { Chart } from 'highcharts-vue';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

// этот компонент работает только при динамическом импорте, иначе крашится в режиме ssr
@Component({
  name: 'AppAveragePriceChart',
  props: {
    average_price: Object as PropType<AveragePriceData>,
    current_price: Number,
  },
  components: {
    highcharts: Chart,
  },
})
export default class AppAveragePriceChart extends Vue {
  // props
  average_price: AveragePriceData;
  current_price: number;

  initOptions() {
    let title: SimpleObject = null;
    const grouped: any = {};
    this.average_price.list.forEach((elem: any[]) => {
      const month = moment(elem[1] as string).format('MMMM YYYY');
      if (!grouped[month]) {
        grouped[month] = [];
      }
      if (elem[2]) {
        grouped[month].push(elem[2]);
      }
    });
    const dates = [];
    const prices = [];
    for (const month in grouped) {
      dates.push(month);
      if (grouped[month].length && grouped[month].length > 2) {
        prices.push(this.median(grouped[month].slice()));
      } else {
        prices.push(mean(grouped[month]));
      }
    }
    // const dates = this.average_price.list.map((arr) => {
    //   return arr[1];
    // });
    // const prices = this.average_price.list.map((arr) => {
    //   return arr[2];
    // });
    dates.reverse();
    prices.reverse();
    if (!this.current_price) {
      title = {
        align: 'left',
        text: `<div class="average-price-chart__chart-head"><span class="average-price-chart__chart-title">Средняя рыночная стоимость</span>
          <p class="average-price-chart__chart-price">${currency(
    this.average_price.avg,
    { symbol: '', separator: ' ', precision: 0 }
  ).format()} ₽</p>`,
        useHTML: true,
      };
    } else {
      const percent = Math.round(
        100 *
          Math.abs(
            (this.average_price.avg - this.current_price) /
              ((this.average_price.avg + this.current_price) / 2)
          )
      );
      let priceChange = `Выше средней рыночной стоимости <span><i class="fas fa-chevron-up"></i> ${percent}%</span>`;
      if (this.current_price < this.average_price.avg) {
        priceChange = `Ниже средней рыночной стоимости <span><i class="fas fa-chevron-down"></i> ${percent}%</span>`;
      }
      title = {
        align: 'left',
        text: `<div class="average-price-chart__chart-head"><span class="average-price-chart__chart-title">Средняя рыночная стоимость</span>
          <p class="average-price-chart__chart-price">${currency(
    this.average_price.avg,
    { symbol: '', separator: ' ', precision: 0 }
  ).format()} ₽</p>
          <span class="average-price-chart__chart-percent">${priceChange}</span></div>`,
        useHTML: true,
      };
    }
    return {
      colors: [ '#2381F9' ],
      title,
      chart: {
        type: 'area',
        backgroundColor: '#FFF5F0',
      },
      xAxis: {
        categories: dates,
      },
      tooltip: {
        backgroundColor: '#3C3C3C',
        style: {
          color: '#fff',
        },
        borderRadius: 15,
      },
      yAxis: {
        title: {
          text: '',
        },
      },

      plotOptions: {
        series: {
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [ 0, 'rgba(35, 129, 249, 0.13)' ],
              [ 1, 'rgba(35, 129, 249, 0)' ],
            ],
          },
        },
      },

      series: [
        {
          name: 'Стоимость',
          data: prices,
        },
      ],
    };
  }

  median(data: number[]) {
    let count = 0;

    data.sort((a: number, b: number) => a - b);

    do {
      data.pop();
      data.shift();
    } while (data.length > 2);

    if (data.length === 2) {
      for (let j = 0; j < data.length; j++) {
        count = data[0] + data[1];
        return count / 2;
      }
    }

    return data[0];
  }
}
